/*
Author       : Dreamguys
Template Name: Dreamschat - Bootstrap Chat Template
Version      : 1.0
*/

(function($) {
    "use strict";

    function test(){
        alert('parfait!!!!!!!!!!!!!');
    }

    $("#search-contact").on("keyup", function() {
        var value = $(this).val().toLowerCase();
        $("#chatsidebar ul li").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });

    jQuery(window).on('load resize', function() {

        // Variable Declarations

        var right_sidebar = $('.right-sidebar').width();
        var left_sidebar = $('.left-sidebar').width();
        var chat_bar = $('.chat').width();
        var win_width = $(window).width();

        $(".user-list-item").on('click', function() {
            if ($(window).width() < 992) {
                $('.left-sidebar').addClass('hide-left-sidebar');
                $('.chat').addClass('show-chatbar');
            }
        });

        $(".dream_profile_menu").on('click', function() {
            $('.right-sidebar').addClass('show-right-sidebar');
            $('.right-sidebar').removeClass('hide-right-sidebar');
            if ($(window).width() > 991 && $(window).width() < 1201) {
                $(".chat").css('margin-left', -chat_bar);
            }
            if ($(window).width() < 992) {
                $('.chat').addClass('hide-chatbar');
            }
        });

        $(".close_profile").on('click', function() {
            $('.right-sidebar').addClass('hide-right-sidebar');
            $('.right-sidebar').removeClass('show-right-sidebar');
            if ($(window).width() > 991 && $(window).width() < 1201) {
                $(".chat").css('margin-left', 0);
            }
            if ($(window).width() < 992) {
                $('.chat').removeClass('hide-chatbar');
            }
        });
        $(".nav-tabs a").on('click', function() {
            $(this).tab('show');
        });

        $(".chat-header .left_side i").on('click', function() {
            $('.left-sidebar').removeClass('hide-left-sidebar');
            $('.chat').removeClass('show-chatbar');
        });

    });

    //Rightside accordian
    $('.accordion-col .accordion-title').on('click', function() {
        $(this).next().slideToggle();
        $(this).toggleClass('active');
    });
    //Custom modal click for status view
    $('*[data-target="#status-modal"]').on('click', function() {
        $('body').addClass('custom-model-open');
    });
    $('.custom-status-close').on('click', function() {
        $('body').removeClass('custom-model-open');
    });

    // Tooltip
    if ($('[data-toggle="tooltip"]').length > 0) {
        $('[data-toggle="tooltip"]').tooltip();
    }

    //Custom scroll bar
    if ($(window).width() > 992) {
        if ($('.chat-body, .left-sidebar .sidebar-body, .right-sidebar').length > 0) {
            $('.chat-body, .left-sidebar .sidebar-body, .right-sidebar').mCustomScrollbar();
        }
        //$('.aga-body').mCustomScrollbar();
    }

    $('#message-input').on('input', function(e) {
        let message = $(this).val();
        if ((message.split(' ').join('')).length > 0) {
            $('.send-btn').attr('disabled', false);
        } else {
            $('.send-btn').attr('disabled', true);
        }
    });

    $("#form-message").submit(function(e) {
        e.preventDefault();

        var now = new Date(Date.now());
        $('.messages').append('<div class="chats chats-right"><div class="chat-content"><div class="message-content">' + $('#message-input').val() + '</div><div class="chat-time"><div><div class="time">' + now.getHours() + ":" + now.getMinutes() + '<i><img src="assets/img/double-tick.png" alt=""></i></div> </div></div></div></div>');
        $('#message-input').val('');
    })

})(jQuery);